
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./dashboard/bootstrap');

window.$ = window.jQuery = require('jquery');

var $grid = null;

$(function() {
    $('span.pie').peity('pie');

    $('.js-delete-warn').on('click', function(e) {
        if (!confirm("Are you sure you want to perform this action?")) {
            return false;
        }
    });

    if ($('#shop-widgets').length) {
        $grid = $('#shop-widgets').isotope({
            itemSelector: '.js-shop-admin-block',
            layoutMode: 'masonry',
            getSortData: {
                orderStatus: '.js-sort-order parseInt',
                name: '.shop-name'
            },
            sortBy: ['orderStatus', 'name']
        });

        $('.js-filter-button-group').on('click', '.btn', function() {
            $('.js-filter-button-group .btn').removeClass('active');
            $(this).addClass('active');

            var filterValue = $(this).attr('data-filter');
            $grid.isotope({ filter: filterValue });
        });
    }

    $('.js-shop-admin-block').each(function() {
        var $block = $(this);
        $.get($block.data('widget-url'), function(data) {
            $block.find('.js-loading').hide();
            $block.find('.js-display').html(data);
            sortShopWidgets($block);
        });

        // Update every 2 minutes without showing loading indicator
        setInterval(function() {
            // Skip if pause block is open on any widget
            if ($('.js-pause-block:visible').length) {
                return;
            }

            $.get($block.data('widget-url'), function(data) {
                // Skip again here in case one was in progress
                if ($('.js-pause-block:visible').length) {
                    return;
                }

                $block.find('.js-display').html(data);
                sortShopWidgets($block);
            });
        }, ((60 * 2) * 1000));
    });

    $('body').on('click', '.js-shop-widget-link-handler', function(e) {
        // Skip if this is a form
        if ($(this).prop('nodeName') == 'FORM') {
            return;
        }

        e.preventDefault();

        if ($(this).data('confirm-message') && !confirm($(this).data('confirm-message'))) {
            return false;
        }

        var data = $(this).data();
        data._token = window.Laravel.csrfToken;

        var hrefAdditional = '';
        if ($(this).data('prompt-message')) {
            var reason = prompt($(this).data('prompt-message'));
            if (reason == null || reason == "") {
                alert('Please provide a reason');
                return;
            }

            hrefAdditional = '?reason=' + reason;
            data.reason = reason;
        }

        var $link = $(this);
        var $parentBlock = $(this).closest('.js-shop-admin-block');
        $parentBlock.find('.js-display').html('');
        $parentBlock.find('.js-loading').show();

        $.ajax({
            url: $link.attr('href') + hrefAdditional,
            type: ($(this).data('method') ? $(this).data('method') : 'GET'),
            data: data,
            success: function(data) {
                if (typeof data.error !== "undefined") {
                    alert(data.error);
                }

                $.get($parentBlock.data('widget-url'), function(data) {
                    $parentBlock.find('.js-loading').hide();
                    $parentBlock.find('.js-display').html(data);
                    sortShopWidgets($parentBlock);
                });
            },
            error: function(data) {
                alert('An error occurred. Please refresh and try again.');
            }
        });
    });

    $('body').on('submit', '.js-shop-widget-form-handler', function(e) {
        e.preventDefault();

        if ($(this).data('confirm-message') && !confirm($(this).data('confirm-message'))) {
            return false;
        }

        var reason = '';
        if ($(this).data('prompt-message')) {
            var reason = prompt($(this).data('prompt-message'));
            if (reason == null || reason == "") {
                alert('Please provide a reason');
                return;
            }
        }

        var $form = $(this);
        var $parentBlock = $(this).closest('.js-shop-admin-block');
        $parentBlock.find('.js-display').html('');
        $parentBlock.find('.js-loading').show();

        $.ajax({
            url: $form.attr('action'),
            type: $form.attr('method'),
            data: $form.serialize() + '&reason=' + reason,
            success: function(data) {
                if (typeof data.error !== "undefined") {
                    alert(data.error);
                }

                $.get($parentBlock.data('widget-url'), function(data) {
                    $parentBlock.find('.js-loading').hide();
                    $parentBlock.find('.js-display').html(data);
                    sortShopWidgets($parentBlock);
                });
            },
            error: function(data) {
                alert('An error occurred. Please refresh and try again.');
            }
        });
    });

    $('body').on('click', '.js-pause-toggle', function(e) {
        e.preventDefault();

        var $block = $(this).closest('.js-shop-admin-block');
        var $mainBlock = $block.find('.js-main');
        var $pauseBlock = $block.find('.js-pause-block');
        if ($pauseBlock.is(":visible")) {
            $pauseBlock.hide();
            $mainBlock.show();
        } else {
            $pauseBlock.show();
            $mainBlock.hide();
        }
    });

    $('body').on('click', '.js-shop-admin-block .js-toggle', function(e) {
        e.preventDefault();

        var $block = $(this).closest('.js-shop-admin-block');
        if ($block.data('expanded') == '1') {
            $block.find('.js-exanded').hide();
            $block.data('expanded', '0');
        } else {
            $block.find('.js-exanded').show();
            $block.data('expanded', '1');
        }

        $grid.isotope('updateSortData').isotope();
    });

    $('body').on('click', '.js-expand-all', function(e) {
        e.preventDefault();

        var $blocks = $('.js-shop-admin-block');
        $blocks.each(function() {
            $(this).find('.js-exanded').show();
            $(this).data('expanded', '1');
        });

        $grid.isotope('updateSortData').isotope();
    });

    $('body').on('click', '.js-collapse-all', function(e) {
        e.preventDefault();

        var $blocks = $('.js-shop-admin-block');
        $blocks.each(function() {
            $(this).find('.js-exanded').hide();
            $(this).data('expanded', '0');
        });

        $grid.isotope('updateSortData').isotope();
    });

    $('.js-availability-toggle').on('change', function() {
        if ($(this).prop('checked')) {
            $(this).closest('.availability-switch').find('.js-availability-times').show();
        } else {
            $(this).closest('.availability-switch').find('.js-availability-times').hide();
        }
    });

    $('.js-availability-link').on('click', function(e) {
        e.preventDefault();
        var $switch = $(this).closest('.availability-switch');
        if ($switch.find('.js-availability-toggle').prop('checked')) {
            $switch.find('.js-availability-times').show();
        }
    });

    $('.js-refire-prompt').on('click', function(e) {
        e.preventDefault();

        var reason = prompt("Please specify a reason for the refire");
        if (reason != null) {
            $(this).parent('form').find('input[name="reason"]').val(reason);
            $(this).parent('form').submit();
        }

        return false;
    });

    $('.js-confirm').on('click', function(e) {
        if (!confirm($(this).data('message'))) {
            return false;
        }
    });

    $('.text-editor-simple').summernote({
      toolbar: [
        ['style', ['bold', 'italic', 'underline']],
        ['view', ['codeview']],
      ]
    });

    $('.text-editor-full').summernote({
      toolbar: [
            ['style', ['style']],
            ['font', ['bold', 'underline', 'clear']],
            ['para', ['ul', 'ol', 'paragraph']],
            ['view', ['fullscreen', 'codeview']]
        ]
    });

    $('.select2').select2();

    $('.js-user-lookup').select2({
      allowClear: true,
      ajax: {
        url: '/dashboard/search/users.json',
        dataType: 'json',
        delay: 250
      },
      templateResult: formatUserLookup
    });

    $('.toast-references').each((index,element) => {
        $(element).select2({
            tags: $(element).hasClass('support-tags'),
            templateSelection: function (reference) {
                if (reference.element.parentElement.label) {
                    return $('<span>' + reference.text + ' <small><b>' + reference.element.parentElement.label + '</b></small></span>');
                }

                return reference.text;
            }
        });
    });

    $('.js-custom-modifiers-switch').on('change', function(e) {
        if ($(this).is(':checked')) {
            $('.js-custom-modifiers').show();
        } else {
            $('.js-custom-modifiers').hide();
        }
    });

    // Set initial state
    if (! $('.js-custom-modifiers-switch').is(':checked')) {
        $('.js-custom-modifiers').hide();
    }
});

function formatUserLookup(user) {
  if (!user.id) {
    return user.text;
  }

  var $user = $(
    '<div><b style="font-size:12px;">' + user.firstname + ' ' + user.lastname + ' </b><span style="font-size:10px;">(' + user.id + ')</span><div style="font-size:10px;">' + (user.email ? user.email : '') + (user.phone ? ' - ' + user.phone : '') + '</div></div>'
  );

  return $user;
};

function sortShopWidgets($block) {
    if ($block.data('expanded') == '1') {
        $block.find('.js-exanded').show();
    }

    $block.removeClass (function (index, className) {
        return (className.match (/(^|\s)status-\S+/g) || []).join(' ');
    });

    $block.addClass('status-' + $block.find('.js-display .js-shop-widget').data('status'));

    $grid.isotope('updateSortData').isotope();
}

$(document).ready(function() {
    $('.js-show-new-hours-entry').click((event) => {
        const button = $(event.target),
            currentRow = button.parents('tr').prev();

        event.preventDefault();
        let copyForm = button.parents('tr').prev().clone();
        copyForm.find('input').val('');
        copyForm.insertAfter(currentRow);
        copyForm.find('button').remove();
        copyForm.find('input')[0].focus();

    });
    $('.js-delete-hours-entry').click((event) => {
        const button = $(event.target),
            currentRow = button.parents('tr');

        event.preventDefault();
        currentRow.remove();
    });
});
